import gql from "graphql-tag"

import { IMAGE_FRAGMENT } from "../fragments/imageFragment"
import { PRICE_RANGE_FRAGMENT } from "../fragments/priceRangeFragment"
import {
  VARIANT_FRAGMENT,
  VARIANT_FRAGMENT_LITE,
} from "../fragments/variantFragment"
import { METAFIELD_FRAGMENT } from "../fragments/metafieldFragment"

export const GET_PRODUCT_LITE = gql`
  query($handle: String!, $firstImages: Int, $firstVariants: Int, $countryCode: CountryCode!) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      id
      handle
      description
      title
      tags
      images(first: $firstImages) {
        edges {
          node {
            ...ImageFragment
          }
        }
      }
      variants(first: $firstVariants) {
        edges {
          node {
            ...CustomizedVariantFragment
          }
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
  ${VARIANT_FRAGMENT}
`

export const GET_PRODUCT_VARIANTS = gql`
  query($handle: String!, $firstVariants: Int, $countryCode: CountryCode!) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      variants(first: $firstVariants) {
        edges {
          node {
            ...VariantFragmentLite
          }
        }
      }
    }
  }
  ${VARIANT_FRAGMENT_LITE}
`

export const GET_GROUPED_PRODUCT_BY_HANDLE = gql`
  query(
    $handle: String!
    $parentQuery: String
    $firstMedia: Int
    $firstVariants: Int
    $firstCollections: Int
    $firstGroupedProducts: Int
    $countryCode: CountryCode!
  ) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      id
      handle
      title
      descriptionHtml
      productType
      vendor
      tags
      availableForSale
      collections(first: $firstCollections) {
        edges {
          node {
            title
            handle
          }
        }
      }
      options {
        id
        name
        values
      }
      priceRange {
        ...PriceRangeFragment
      }
      media(first: $firstMedia) {
        edges {
          node {
            ... on Video {
              id
              mediaContentType
              previewImage {
                ...ImageFragment
              }
              sources {
                url
                format
                mimeType
                width
                height
              }
            }
            ... on MediaImage {
              id
              image {
                ...ImageFragment
              }
              mediaContentType
            }
          }
        }
      }
      variants(first: $firstVariants) {
        edges {
          node {
            ...CustomizedVariantFragment
          }
        }
      }
    }
    grouped: products(query: $parentQuery, first: $firstGroupedProducts) {
      edges {
        node {
          id
          title
          handle
          tags
          availableForSale
          descriptionHtml
          variants(first: $firstVariants) {
            edges {
              node {
                ...CustomizedVariantFragment
              }
            }
          }
          options {
            id
            name
            values
          }
          media(first: $firstMedia) {
            edges {
              node {
                ... on Video {
                  id
                  mediaContentType
                  previewImage {
                    ...ImageFragment
                  }
                  sources {
                    url
                    format
                    mimeType
                    width
                    height
                  }
                }
                ... on MediaImage {
                  id
                  image {
                    ...ImageFragment
                  }
                  mediaContentType
                }
              }
            }
          }
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
  ${PRICE_RANGE_FRAGMENT}
  ${VARIANT_FRAGMENT}
`

export const GET_PRODUCT_COMPLETE = gql`
  query(
    $id: ID!
    $handle: String!
    $parentQuery: String
    $firstMedia: Int
    $firstVariants: Int
    $firstCollections: Int
    $firstMetafields: Int
    $countryCode: CountryCode!
  ) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      id
      handle
      title
      descriptionHtml
      productType
      vendor
      tags
      availableForSale
      collections(first: $firstCollections) {
        edges {
          node {
            title
            handle
          }
        }
      }
      options {
        id
        name
        values
      }
      priceRange {
        ...PriceRangeFragment
      }
      media(first: $firstMedia) {
        edges {
          node {
            ... on Video {
              id
              mediaContentType
              previewImage {
                ...ImageFragment
              }
              sources {
                url
                format
                mimeType
                width
                height
              }
            }
            ... on MediaImage {
              id
              image {
                ...ImageFragment
              }
              mediaContentType
            }
          }
        }
      }
      metafields(identifiers: ["shopifyMetafieldsParentId"]) {
        edges {
          node {
            ...MetafieldFragment
          }
        }
      }
      variants(first: $firstVariants) {
        edges {
          node {
            ...CustomizedVariantFragment
          }
        }
      }
    }
    grouped: products(query: $parentQuery, first: $firstVariants) {
      edges {
        node {
          id
          title
          handle
          tags
          publishedAt
        }
      }
    }
    recommendations: productRecommendations(productId: $id) {
      handle
      tags
      publishedAt
    }
  }
  ${IMAGE_FRAGMENT}
  ${PRICE_RANGE_FRAGMENT}
  ${VARIANT_FRAGMENT}
  ${METAFIELD_FRAGMENT}
`

export const GET_PRODUCTS_BY_HANDLE = handles => gql`
  query(
    $firstImages: Int
    $firstVariants: Int
    $firstMetafields: Int
    $countryCode: CountryCode!
  ) @inContext(country: $countryCode) {
    ${handles?.map(
      handle => `
      product${handle.replace(/-/g, "")}: productByHandle(handle: "${handle}") {
        id
        handle
        title
        tags
        options {
          id
          name
          values
        }
        images(first: $firstImages) {
          edges {
            node {
              ...ImageFragment
            }
          }
        }
        metafields(first: $firstMetafields) {
          edges {
            node {
              ...MetafieldFragment
            }
          }
        }
        variants(first: $firstVariants) {
          edges {
            node {
              ...CustomizedVariantFragment
            }
          }
        }
      }
    `,
    )}
  }
  ${IMAGE_FRAGMENT}
  ${VARIANT_FRAGMENT}
  ${METAFIELD_FRAGMENT}
`

export const GET_GROUPED_PRODUCTS_BY_HANDLE = products => gql`
  query(
    $firstImages: Int
    $firstVariants: Int
    $countryCode: CountryCode!
  ) @inContext(country: $countryCode) {
    ${products?.map(
      ({ handle, tags }) => `
      product${handle.replace(/-/g, "")}: productByHandle(handle: "${handle}") {
        id
        handle
        title
        tags
        options {
          id
          name
          values
        }
        images(first: $firstImages) {
          edges {
            node {
              ...ImageFragment
            }
          }
        }
        publishedAt
        variants(first: $firstVariants) {
          edges {
            node {
              ...CustomizedVariantFragment
            }
          }
        }
      }
      productgrouped${handle.replace(
        /-/g,
        "",
      )}: products(query: "tag:'${tags?.find(tag =>
        tag.includes(`x-sibling-handle:${handle}`),
      )}' -tag:'parent'", first: 250) {
      edges {
        node {
          id
          title
          handle
          tags
          options {
            id
            name
            values
          }
          publishedAt
          images(first: $firstImages) {
            edges {
              node {
                ...ImageFragment
              }
            }
          }
          variants(first: $firstVariants) {
            edges {
              node {
                ...CustomizedVariantFragment
              }
            }
          }
        }
      }
    }
    `,
    )}
  }
  ${IMAGE_FRAGMENT}
  ${VARIANT_FRAGMENT}
`

export const PRICE_FRAGMENT = gql`
  fragment PriceFragment on ProductVariant {
    priceV2 {
      amount
      currencyCode
    }
    compareAtPriceV2 {
      amount
      currencyCode
    }
  }
`

export const VARIANT_FRAGMENT_AVAILABILITY = gql`
  fragment VariantFragmentAvailability on ProductVariant {
    id
    sku
    availableForSale
    currentlyNotInStock
    title
    quantityAvailable
    ...PriceFragment
  }
  ${PRICE_FRAGMENT}
`

export const GET_PRODUCT_AVAILABILITY = gql`
  query ($countryCode: CountryCode!, $handle: String!) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      tags
      variants(first: 100) {
        edges {
          node {
            ...VariantFragmentAvailability
          }
        }
      }
    }
  }
  ${VARIANT_FRAGMENT_AVAILABILITY}
`